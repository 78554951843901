import React from "react"
import AboutLayout from "../../components/layouts/aboutLayout"

const MembershipPage = props => (
  <AboutLayout pageTitle="Membership Benefits">
    <h2>Why Join The Chamber</h2>
    <p>
      <br />
      Your partnership with the Claxton – Evans County Chamber of Commerce is an
      investment in your business/club/organization/self and your community. By
      taking advantage of the Chamber’s many programs and services, we are
      confident that you will find a substantial return on your investment.
    </p>
    <br />
    <br />
    <h2>Weekly E-mails</h2>
    <p>
      Receive weekly updates about upcoming events, things going on in the
      community, and other useful news and information.
    </p>
    <br />
    <br />

    <h2>Business Referrals</h2>
    <p>
      As an integral community information hub, the Chamber receives inquiries
      for business referrals on a regular basis. Your partnership ensures that
      you are included in our database of businesses that are referred to those
      who call, walk-in, email, and submit online inquiries. The Chamber refers
      only Chamber Members as preferred businesses.
    </p>
    <br />
    <br />

    <h2>Business Support</h2>
    <p>
      The Chamber associates and board members know that owning and/or managing,
      and working can be exhausting. No matter how much you love what you do,
      working long hours can wear anyone out. Reach out to us, let us know your
      thoughts on challenges you are facing, give us the opportunity to connect
      you with resources. Together, we can help make your business and your
      community thrive.
    </p>
    <br />
    <br />
    <h2>
      Celebrate Yourself – Ribbon Cuttings, Groundbreakings, Special Events,
      Grand Openings, Etc.
    </h2>
    <p>
      The Chamber offers support for Chamber Member’s special events such as
      grand openings, re-openings, groundbreakings, ribbon cuttings, anniversary
      celebrations, Open Houses etc. This exclusive partner support includes
      Chamber representation at your event (along with ceremonial scissors,
      ribbon, or shovels as necessary) and promotion of the event in our weekly
      e-mail updates and social media.
    </p>
    <br />
    <br />
    <h2>Online Business Directory Listing</h2>
    <p>
      *Coming Soon* Chamber Members will receive complimentary listing in the
      Chamber’s online business directory. The directory will be searchable by
      business name, category, or keywords. Because Google recognizes Chambers
      of Commerce as “trusted” links, your rankings in online searches are given
      priority. Don’t miss out on this 24/7 referral opportunity.
    </p>
    <br />
    <br />
    <h2>Member Decal</h2>
    <p>
      For joining, you will receive a static window cling “Chamber Member”
      decal. By displaying this, your business will have increased credibility.
    </p>
    <br />
    <br />
    <h2>Chamber Partner Logo</h2>
    <p>
      Chamber Members have access to the Chamber’s logo for use on your website
      and publications. Let everyone know you are a partner!
    </p>
    <br />
    <br />
    <h2>Connecting & Networking</h2>
    <p>
      This may be the most valuable benefit of being a Chamber Member. Make new
      connections and meet the key influencers of partner companies at
      networking functions such as Business After Hours, Chamber Banquet,
      Chamber Quarterly Meetings, The Rotary/Chamber Auction, and more! With a
      network of partner representatives, you are sure to find new and valuable
      connections. Also, being a Chamber Member ensures your name is included in
      the jackpot drawing at all Business After Hours events.
    </p>
    <br />
    <br />
    <h2>Exclusive Access to Marketing and Sponsorship Opportunities</h2>
    <p>
      As a Chamber Member, your business is now eligible for unique
      opportunities to market and promote your business. Our marketing
      opportunities include affordable advertising in Chamber sponsored event
      publications and online. Our valuable sponsorship opportunities create
      visibility for your business.
    </p>
    <br />
    <br />
    <h2>Notary Public</h2>
    <p>
      The Chamber employs a Notary Public. Simply stop by the office during
      regular business hours with the documents to be notarized and we are happy
      to notarize your signature – free of charge, of course.
    </p>
    <br />
    <br />
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2023_Membership_Application.pdf">Join Now!</a>
  </AboutLayout>
)

export default MembershipPage
